export enum EFilenameBlur {
  'card/article-default.png' = 'card/article-default.png',
  'product/flag-ch.png' = 'product/flag-ch.png',
  'product/fire.png' = 'product/fire.png',
  'product/rocket.png' = 'product/rocket.png',
  'product/stopwatch.png' = 'product/stopwatch.png',
  'homepage/gold-1kilo-mobile.jpg' = 'homepage/gold-1kilo-mobile.jpg',
  'about-us/alessandro.jpg' = 'about-us/alessandro.jpg',
  'about-us/omar.jpg' = 'about-us/omar.jpg',
  'auto-savings/calendar-header.png' = 'auto-savings/calendar-header.png',
  'homepage/pamp.png' = 'homepage/pamp.png',
  'maintenance/maintenance.png' = 'maintenance/maintenance.png',
  'gold-1g-front.png' = 'gold-1g-front.png',
  'faq/gold.png' = 'faq/gold.png',
  'homepage/silver-1kilo.png' = 'homepage/silver-1kilo.png',
  'homepage/investment-assistant.png' = 'homepage/investment-assistant.png',
  'homepage/gold-1kilo-mobile.jp' = 'homepage/gold-1kilo-mobile.jp',
  'homepage/gold-1kilo.jpg' = 'homepage/gold-1kilo.jpg',
  'gold-1g-back.png' = 'gold-1g-back.png',
  'homepage/logos.png' = 'homepage/logos.png',
  'shop/precious-metal-gold.png' = 'shop/precious-metal-gold.png',
  'shop/precious-metal-silver.png' = 'shop/precious-metal-silver.png',
  'card/product-default.png' = 'card/product-default.png',
  'silver-landing/good-time-to-invest-card-1.png' = 'silver-landing/good-time-to-invest-card-1.png',
  'silver-landing/good-time-to-invest-card-2.png' = 'silver-landing/good-time-to-invest-card-2.png',
  'silver-landing/silver-kilo.png' = 'silver-landing/silver-kilo.png',
  'silver-landing/banner-silver-kilo.png' = 'silver-landing/banner-silver-kilo.png',
  'silver-landing/trusted-partners-logos.png' = 'silver-landing/trusted-partners-logos.png',
  'storage-solution/secure_partners.png' = 'storage-solution/secure_partners.png',
  'storage-solution/gold-1kg.png' = 'storage-solution/gold-1kg.png',
  'storage-solution/silver-kilo-mobile.png' = 'storage-solution/silver-kilo-mobile.png',
  'charts/content-block-metals.png' = 'charts/content-block-metals.png',
}
